  .carousel-container {
    width: 50%;
    /* 控制Carousel的宽度为容器的50% */
    margin: auto;
    /* 居中显示 */
  }

  .carousel-item img {
    width: 100%;
    /* 使图片宽度填满Carousel项 */
    height: auto;
    /* 高度自动调整以保持图片比例 */
  }

  .text-section p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #333;
  }

  .text-section .chinese {
    font-family: 'Noto Serif SC', serif;
    /* 使用适合中文显示的字体 */
  }

  .text-section .english {
    font-family: 'Arial', sans-serif;
    /* 使用适合英文显示的字体 */
  }


  .hover-effect {
    transition: transform 0.3s ease-in-out;
  }

  .hover-effect:hover {
    transform: scale(1.05);
    /* 鼠标悬停时放大元素 */
  }

  .fuyu-container {
    max-width: 100%;
    /* 图片宽度不超过容器宽度的100% */
    max-height: 80vh;
    /* 图片高度不超过视口高度的80% */
    height: auto;
    /* 高度自适应 */
    margin: auto;
    /* 水平和垂直居中 */
    display: block;
    /* 避免行内元素特性影响布局 */
  }

  .custom-carousel-size {
    max-width: 35%;
    /* 调整宽度 */
    margin: auto;
    /* 居中显示 */
    transform: scale(0.9);
    /* 缩小到原始尺寸的90% */
  }

  .scale-container {
    width: 50%;
    margin: auto;
  }

  @media (max-width: 768px) {
    .carousel-container {
      width: 80%;
      margin: auto;
    }
    .scale-container {
      width: 70%;
      margin: auto;
    }
    .custom-carousel-size {
      max-width: 60%;
      margin: auto;
    }
  }